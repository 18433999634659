body {
  margin: 0;
  font-family: HelveticaNeue, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'HelveticaNeue';
  src:
    local('HelveticaNeue'),
    url(./assets/fonts/HelveticaNeueLTStd-Lt.otf) format('opentype');
}
